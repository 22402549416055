import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Post from 'templates/Post'
import Meta from 'components/Meta'
import Layout from 'components/Layout'
import Page from 'templates/Page'

class Template extends React.Component {
  render() {
    const { data, location, pageContext } = this.props
    const posts = data.remark.posts

    let index = 0
    posts.forEach(function(post, idx) {
      if (post.post.frontmatter.path == location.pathname) {
        index = idx
      }
    })

    const prev_post = index === 0 ? null : posts[index - 1].post
    const next_post = index === posts.length - 1 ? null : posts[index + 1].post

    return (
      <div>
        <Layout location={location}>
          <Meta
            site={get(data, 'site.meta')}
            title={get(data, 'post.frontmatter.title')}
            description={get(data, 'post.frontmatter.description')}
            image={get(
              data,
              'post.frontmatter.image.childImageSharp.fluid.src'
            )}
            path={get(data, 'post.frontmatter.path')}
          />
          {get(data, 'post.frontmatter.layout') != 'page' ? (
            <Post
              data={get(data, 'post')}
              options={{
                isIndex: false,
                adsense: get(data, 'site.meta.adsense'),
              }}
              prev_post={prev_post}
              next_post={next_post}
            />
          ) : (
            <Page {...this.props} />
          )}
        </Layout>
      </div>
    )
  }
}

export default Template

export const pageQuery = graphql`
  query PostByPath($path: String!) {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter_url
        adsense
      }
    }

    post: markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        layout
        title
        path
        category
        tags
        description
        date(formatString: "MMMM Do, YYYY")
        image {
          childImageSharp {
            fluid(maxWidth: 750) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            tags
            description
            date(formatString: "MMMM Do, YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 360, maxHeight: 134) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
